@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

*, body, html {
    margin: 0;
    padding: 0;
}

.layout {
    /*height: 100vh;*/
    min-height: 100vh;
}

/* HEADER */
.menu {
    background-color: #204E57 !important;
}

.logo {
    font-weight: bold;
    margin-right: -60px;
}

.logoutBtn {
    color: white;
    opacity: 70%;
}

.logoutBtn:hover {
    color: white !important;
    opacity: 100%;
}

/* CONTENT */
.content {
    text-align: center;
    color: #204E57;
    /*height: 88vh;*/
}

/* FOOTER */
.footer {
    height: 6vh;
    background-color: #204E57;
}

.footerLabel {
    color: white;
    margin-top: -2px;
    /* font-family: 'Open Sans', sans-serif; */
}

/* LOGIN PAGE */
.loginPage {
    display: flex;
    justify-content: center;
    height: 50vh;
    padding: 30px;
}

.loginDivider {
    height: 150px;
}

.loginLabel {
    margin-top: -60px;
}

.loginForm {
    width: 260px;
}

/* REGISTRATION */
.registrationLabel,
.mainLabel {
    margin-top: 40px;
    font-family: 'Open Sans', sans-serif;
}

.addUserBtn {
    margin-bottom: 20px;
}

.addDriverBtn {
    margin-top: 20px;
}

.label {
    font-family: 'Open Sans', sans-serif;
}

/* PENDING */
.pendingDate {
    margin-top: 40px;
}

.searchClients {
    width: 250px;
    margin: 0 0 20px 20px;
}

.weekDayCards {
    width: 50vw;
}

.scheduleTime {
    text-align: center;
}

.scheduleCard {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.selectedDay {
    font-weight: bold;
    text-decoration: underline;
}

.createRouteBtn {
    margin-bottom: 20px;
}

.radioWeek {
    margin-top: 20px;
}

/* CLIENT DETAILS */
.clientCard {
    width: 1200px;
    margin-top: 20px;
}

/* */

.scrolledBox {
    /*overflow: auto;*/
    /*max-height: 600px;*/
    /*display: block;*/
    min-height: 600px;
    margin: 0 0 20px 0;
}


/* Custom scrollbar for WebKit browsers */
::-webkit-scrollbar {
    width: 5px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
    background: #888; /* Thumb color */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb color on hover */
}

/* Custom scrollbar for Firefox */
* {
    scrollbar-width: thin; /* 'auto' or 'thin' */
    scrollbar-color: #888 #f1f1f1; /* Thumb and track color */
}


/* --------------------------------------------------------------------------------------------------------*/
.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-drawer-header {
    background-color: #001529 !important;
}

.ant-drawer-title, .ant-menu-item, .ant-menu-item a {
    color: #fff !important;
}

/* --------------------------------------------------------------------------------------------------------*/