.today-card {
    background-color: rgba(1, 219, 223, 0.07);
}

.today-card h4 {
    color: #2696a1 !important;
}

.scroll-container * {
    user-select: auto;
}

.weekly-schedule {
    padding: 20px;
    white-space: nowrap;
    overflow-x: auto;
    cursor: grab; /* Set the cursor to grab when hovering over the schedule */
}

.schedule-container {
    display: flex;
    flex-direction: row; /* Change to row to align cards horizontally */
    gap: 20px;
}

.schedule-container .day-card:hover {
    background: rgba(255, 255, 255, 0.04);
}


.day-card {
    display: inline-block;
    margin-bottom: 10px;
    min-width: 465px;
    padding: 0 0 0 45px;
    min-height: 75vh;
}

.schedule-item {
    border-left: 4px solid;
    padding: 8px;
    margin-bottom: 8px;
    position: relative;
}

.small-text {
    font-size: 12px;
    line-height: 1.2;
}

.timeline-item {
    padding: 4px 0;
    margin-right: 16px; /* Add margin-right to control the space between timeline items and the card */
}

.timeline-time {
    margin-bottom: 16px; /* Adjust this value to control the space between time ranges */
}

.driver-info {
    display: flex;
    align-items: start;
    /*margin-bottom: 8px;*/
}

.driver-initials {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #2c9ba6;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    /*margin-right: 8px;*/
}

.action-buttons {
    display: flex;
    gap: 10px;
    justify-content: end;
}

/* Set cursor to grabbing when scrolling */
.weekly-schedule:active {
    cursor: grabbing;
}

.schedule-item:hover {
    cursor: default;
}


/*-------------- Scroll --------------*/
/* Custom Scrollbar Styles */
.custom-scrollbar {
    overflow-x: auto;
    overflow-y: hidden;
    height: 40px; /* Custom height */
}

.custom-scrollbar::-webkit-scrollbar {
    height: 10px; /* Height of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #3498db; /* Custom color */
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); /* Custom box-shadow */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #33a0aa; /* Darker color on hover */
}

/* Hide default scrollbar in Firefox */
.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #33a0aa #f1f1f1;
    /*height: 30px;*/
}

/* Apply shadow effect to the entire scrollbar container */
.custom-scrollbar-container {
    box-shadow: 0 4px 2px -2px gray; /* Box-shadow effect */
    /*height: 30px;*/
    overflow-x: auto;
}

/*-------------- Scroll --------------*/