/* OrderCollapse.css */

.custom-collapse-header {
    border-radius: 4px;
    font-size: 16px;
    /*font-weight: bold;*/
    padding: 10px;
    transition: background-color 0.3s;
}

.regular_pickup .ant-collapse-header {
    background-color: rgba(43, 87, 95, 0.05);
}

.on_call_routine .ant-collapse-header {
    background-color: rgba(220, 113, 113, 0.23);
}

.on_call_stat .ant-collapse-header {
    background-color: rgba(220, 113, 113, 0.55);
}