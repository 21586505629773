.weekly-schedule {
    padding: 20px;
}

.scroll-container {
    overflow-x: auto;
    padding-bottom: 10px;
}

.scroll-row {
    display: flex;
    flex-wrap: nowrap;
}

.day-col {
    flex: 0 0 500px; /* Set a fixed width for each card */
    max-width: 500px;
    /*margin-right: 16px; !* Adjust margin as needed *!*/
    overflow: hidden;
}

.day-card {
    background: #f0f2f5;
    /*border-radius: 10px;*/
}

.schedule-item {
    padding: 10px;
    border-left: 5px solid;
    border-radius: 5px;
    margin-bottom: 10px;
    background: #fff;
}

.schedule-item:hover {
    background: #fafafa;
}
