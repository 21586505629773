.custom-modal .ant-modal-content {
    border-radius: 8px;
    padding: 20px;
    animation: modalFade 0.5s;
}

.custom-modal .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.75);
}

.main-label {
    margin-bottom: 16px;
}

.textarea {
    margin-bottom: 20px;
}

.select-full-width {
    width: 100%;
    margin-bottom: 20px;
}

.submit-button {
    width: 100%;
}

.close-button {
    width: 100%;
    margin-top: 0;
}

.okay-button {
    display: block;
    margin: 20px auto;
    width: 100px;
}

@keyframes modalFade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.radio-group-full-width {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.radio-item {
    width: calc(33.333% - 10px); /* Adjust the width to fit 3 items in a row */
    /*margin-bottom: 16px; !* Adjust the bottom margin for vertical spacing *!*/
    padding: 10px; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensure padding doesn't affect the width calculation */
}

.no-scroll {
    overflow: hidden;
}


/* ------------------CHECKBOX------------------*/
/* MyModalComponent.css */
.custom-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
}

.custom-checkbox .ant-checkbox-inner::after {
    width: 10px;
    height: 10px;
    left: 5px;
    top: 10px;
    border-width: 2px;
}

.custom-checkbox .ant-checkbox {
    transform: scale(1.2);
}

/* ------------------CHECKBOX------------------*/